<template>
  <div class="org-detail-page" v-loading="loading">
    <org-detail :activity-type-list="activityTypeList" :data="data" v-if="data"></org-detail>
    <div class="back">
      <fm-btn @click="back">返回</fm-btn>
    </div>
  </div>
</template>

<script>
import OrgDetail from './show/detail'

import {
  activityTypeRequest
} from '../../api'

export default {
  components: {
    OrgDetail
  },
  data () {
    return {
      dataId: null,
      loading: false,
      openDialog: false,
      activityTypeList: []
    }
  },
  created () {
    this.$store.dispatch('loadOrgList')
    this.loadActivityTypeList()
  },
  activated () {
    this.dataId = Number(this.$router.history.current.query.id)
  },
  computed: {
    data () {
      return this.$store.getters.orgList.map(v => v.data).find(v => v.id === this.dataId)
    }
  },
  methods: {
    async loadActivityTypeList () {
      this.activityTypeList = await activityTypeRequest.get()
    },
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="less">
.org-detail-page {
  border-radius: 8px;
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background: #fff;
  position: relative;
  .back {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}
</style>
