<template>
  <div class="content">
    <fm-table-new
      :full="true"
      auto-height
      border="row"
      :stripe="false"
      emptyPlaceholder="-"
      :columns="columns"
      :data-list="workerList"
    ></fm-table-new>
  </div>
</template>

<script>
import { workerRequest } from '../../../api'

export default {
  props: {
    orgId: {
      type: Number
    }
  },
  data () {
    return {
      workerList: [],
      columns: [{
        field: 'name',
        fixed: 'left',
        title: '党员姓名'
      },
      { title: '性别', field: 'sex', sort: true, width: 120},
      { title: '民族', width: 120, field: 'clan', sort: true },
      { title: '身份证号码', field: 'idNo', sort: true, width: 184 },
      {
        title: '出生年月', field: 'birth', width: 250, sort: true, dataType: Date, filterRange: true,
        render: (h, rowData) => {
          return h('div', rowData && rowData.birth ? rowData.birth.slice(0, 10) : '-')
        }
      },
      {
        title: '年龄', field: 'age', sort: true, dataType: Number, width: 120, filterRange: true
      },
      {
        title: '入党日期', field: 'rdsj', width: 250, sort: true, dataType: Date, filterRange: true,
        render: (h, rowData) => {
          return h('div', rowData && rowData.rdsj ? rowData.rdsj.slice(0, 10) : '-')
        }
      },
      {
        title: '转正日期', field: 'zzrq', width: 250, sort: true, dataType: Date, filterRange: true,
        render: (h, rowData) => {
          return h('div', rowData && rowData.zzrq ? rowData.zzrq.slice(0, 10) : '-')
        }
      },
      {
        title: '党龄', field: 'dage', sort: true, dataType: Number, width: 120, filterRange: true
      },
      { title: '学历', field: 'education', width: 120, sort: true },
      { title: '学位', field: 'degree', width: 120, sort: true },
      { title: '联系电话', field: 'phone', width: 120, sort: true }]
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      let datas = await workerRequest.get()
      this.workerList = datas.filter(v => v.userOrgRoleList && v.userOrgRoleList.find(v1 => v1.orgId === this.orgId))
    }
  }
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;

}
</style>
