<template>
  <div class="content">
    <fm-table-new
      :full="true"
      auto-height
      border="row"
      :stripe="false"
      emptyPlaceholder="-"
      :columns="columns"
      :data-list="dataList"
    ></fm-table-new>
  </div>
</template>

<script>
import { workerCertificateRequest } from '../../../api'

export default {
  props: {
    orgId: {
      type: Number
    }
  },
  data () {
    return {
      dataList: [],
      columns: [{
        title: '年份',
        sort: true,
        field: 'xlmc'
      },
      {
        title: '上下半年',
        sort: true,
        field: 'practiceAddress'
      },
      {
        title: '党费',
        sort: true,
        field: 'pro'
      }]
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      let datas = await workerCertificateRequest.get({
        type: 'dfjn'
      })
      this.dataList = datas.filter(v => v.workerId === this.orgId)
    }
  }
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;

}
</style>
