import { render, staticRenderFns } from "./dfjn.vue?vue&type=template&id=00d8470a&scoped=true&"
import script from "./dfjn.vue?vue&type=script&lang=js&"
export * from "./dfjn.vue?vue&type=script&lang=js&"
import style0 from "./dfjn.vue?vue&type=style&index=0&id=00d8470a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d8470a",
  null
  
)

export default component.exports