<template>
  <div class="content activity-detail">
    <div class="title">
      {{orgData.name}}
      <span>{{otherInfo.djppmc}}</span>
    </div>
    <div class="info">
      <div class="fm-img" v-if="imgSrc">
        <img :src="imgSrc"/>
      </div>
      <div class="fm-img fm-img-n" v-loading="loadingFile" v-else>未上组织封面</div>
      <div class="info-right">
        <div class="info-text">
          <div class="title-s">品牌理念</div>
          <div class="text-c l-text">
            <div style="text-indent:10px;" v-for="(item, index) in contentListPpll" :key="index">{{item}}</div>
          </div>
          <div class="title-s" style="margin-top: 10px;">工作成效</div>
          <div class="text-c l-text">
            <div style="text-indent:10px;" v-for="(item, index) in contentListGzcx" :key="index">{{item}}</div>
          </div>
        </div>
        <div class="info-lxfs">
          <div class="lxfs-item">
            <div class="title-s">
              地址
            </div>
            <div class="text-c">{{otherInfo.zzdz}}</div>
          </div>
          <div class="lxfs-item">
            <div class="title-s">
              服务时间
            </div>
            <div class="text-c">{{otherInfo.fwsjd}}</div>
          </div>
          <div class="lxfs-item" style="border-right: 0;">
            <div class="title-s">
              联系电话
            </div>
            <div class="text-c">{{otherInfo.lxdh}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="activity-info" v-loading="loading">
      <fm-title title-text="">
        <div class="m-list">
          <div @click="aMenu = item.key" :class="{'m-item-a': aMenu === item.key}" class="m-item" v-for="item in menus" :key="item.key">{{item.label}}</div>
        </div>
      </fm-title>
      <div class="at-list" v-if="aMenu === 'zzsh'">
        <div class="at-item" @click="aType1 = item.id" :class="{'at-item-a': aType1 === item.id}" v-for="item in aTypeList1" :key="item.id">{{item.name}}</div>
      </div>
      <div class="a-list" style="height: calc(100% - 88px);" v-if="aMenu === 'zzsh'">
        <div class="a-item" @click="goActivityDetail(item)" v-for="item in dataList.filter(v => aType1 === 'all' || v.activityTypeId === aType1)" :key="item.id">
          <activity-item style="margin-top: 0;" :data="item"></activity-item>
        </div>
        <div class="no-data" v-if="dataList.filter(v => aType1 === 'all' || v.activityTypeId === aType1).length === 0">
          <img draggable="false" src="/static/images/no-data.png" style="width: 100px; margin: 0px auto;">
        </div>
      </div>
      <div class="at-list" v-if="aMenu === 'djhd'">
        <div class="at-item" @click="aType2 = item.id" :class="{'at-item-a': aType2 === item.id}" v-for="item in aTypeList2" :key="item.id">{{item.name}}</div>
      </div>
      <div class="a-list" style="height: calc(100% - 88px);" v-if="aMenu === 'djhd'">
        <div class="a-item" @click="goActivityDetail(item)" v-for="item in dataListDj.filter(v => aType2 === 'all' || v.activityTypeId === aType2)" :key="item.id">
          <activity-item style="margin-top: 0;" :data="item"></activity-item>
        </div>
        <div class="no-data" v-if="dataListDj.filter(v => aType2 === 'all' || v.activityTypeId === aType2).length === 0">
          <img draggable="false" src="/static/images/no-data.png" style="width: 100px; margin: 0px auto;">
        </div>
      </div>
      <div class="a-list" v-if="aMenu === 'bzqk'">
        <Group style="width: 100%;height: 100%;" :orgId="data.id" v-if="data && data.id"></Group>
      </div>
      <div class="a-list" v-if="aMenu === 'dyqk'">
        <Worker style="width: 100%;height: 100%;" :orgId="data.id" v-if="data && data.id"></Worker>
      </div>
      <div class="a-list" v-if="aMenu === 'dfjn'">
        <Dfjn style="width: 100%;height: 100%;" :orgId="data.id" v-if="data && data.id"></Dfjn>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fileRequest
} from '@/api'

import {
  activityRequest
} from '../../../api'

import ActivityItem from '../../activity/cmp/item'
import Group from './group'
import Worker from './worker'
import Dfjn from './dfjn'

export default {
  components: {
    ActivityItem, Group, Worker, Dfjn
  },
  props: {
    data: {
      type: Object
    },
    activityTypeList: {
      type: Array
    }
  },
  data () {
    return {
      menus: [{key: 'zzsh', label: '组织生活'}, {key: 'djhd', label: '党建+'}, {key: 'bzqk', label: '班子情况'}, {key: 'dyqk', label: '党员情况'}, {key: 'dfjn', label: '党费缴纳'}],
      aMenu: 'zzsh',
      orgData: {},
      otherInfo: {},
      contentListPpll: [],
      contentListGzcx: [],
      loadingFile: false,
      imgSrc: null,
      aType1: 'all',
      aType2: 'all',
      dataList: [],
      dataListDj: [],
      activityTypeId: null,
      loading: false
    }
  },
  watch: {
    data: {
      handler () {
        let data = JSON.parse(JSON.stringify(this.data))
        this.orgData = data
        this.otherInfo = data.description ? JSON.parse(data.description) : {}
        this.contentListPpll = this.otherInfo.ppll ? this.otherInfo.ppll.split('\n') : []
        this.contentListGzcx = this.otherInfo.gzcx ? this.otherInfo.gzcx.split('\n') : []
        if (this.otherInfo.coverFileId) {
          this.loadFile()
        }
        this.loadData()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    aTypeList1 () {
      let data = this.activityTypeList ? this.activityTypeList.filter(v => v.id < 12 && ![7, 8].includes(v.id)) : []
      data = [{
        id: 'all',
        name: '全部'
      }].concat(data)
      return data
    },
    aTypeList2 () {
      let data = this.activityTypeList ? this.activityTypeList.filter(v => v.id > 11) : []
      data = [{
        id: 'all',
        name: '全部'
      }].concat(data)
      return data
    }
  },
  methods: {
    async loadData () {
      if (!this.data || !this.data.id) {
        this.dataList = []
        this.dataListDj = []
        return
      }
      this.loading = true
      let parm = {
        hostOrgId: this.data.id
      }
      if (this.activityTypeId) {
        parm.activityTypeId = this.activityTypeId
      }
      let datas = await activityRequest.get(parm) 
      datas.forEach(v => {
        v.activityTypeName = v.activityType ? v.activityType.name : null
        v.statusText = (this.$store.getters.activityStatusList.find(v1 => v.status === v1.key) || this.$store.getters.activityStatusList[0]).label
      })
      this.dataList = datas.filter(v => v.activityTypeId < 12)
      this.dataListDj = datas.filter(v => v.activityTypeId > 11)
      this.loading = false
    },
    goActivityDetail (data) {
      this.$router.push({
        name: 'hrms.activity.detail',
        query: {
          id: data.id
        }
      })
    },
    async loadFile () {
      this.imgSrc = null
      this.loadingFile = true
      try {
        let fileData = await fileRequest.download({
          id: this.orgData.otherInfo.coverFileId
        })
        if (fileData) {
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.imgSrc = src
        } else {
          this.imgSrc = null
        }
      } catch (error) {
        this.imgSrc = null
      }
      this.loadingFile = false
    }
  }
}
</script>

<style scoped lang="less">
@a-height: 400px;
.activity-detail {
  padding: 10px 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    span {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .title-s {
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }
  .info {
    height: calc(100% - @a-height - 50px);
    display: flex;
    .fm-img {
      height: 100%;
      width: calc(50% - 20px);
      display: flex;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
      }
    }
    .info-text {
      height: calc(100% - 100px);
      padding: 10px;
      overflow-y: auto;
      .l-text {
        min-height: 60px;
      }
    }
    .info-lxfs {
      height: 100px;
      border-top: 1px solid rgba(68, 96, 187, 0.2);
      overflow-y: auto;
      display: flex;
    }
    .fm-img-n {
      border: 1px solid rgba(68, 96, 187, 0.2);
    }
    .info-right {
      height: 100%;
      width: 50%;
      border: 1px solid rgba(68, 96, 187, 0.2);
      border-radius: 5px;
    }
    .lxfs-item {
      border-right: 1px solid rgba(68, 96, 187, 0.2);
      padding: 10px;
      flex: 1;
    }
  }
  .activity-info {
    height: @a-height;
    width: 100%;
    .a-list {
      height: calc(100% - 48px);
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      justify-content: space-between;
      align-items: flex-start;
    }
    .a-item {
      margin-right: 20px;
    }
    .no-data {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
  .content {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .m-list {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .m-item {
      white-space: nowrap;
      letter-spacing: 0.06rem;
      font-weight: 800;
      font-size: 16px;
      margin-right: 30px;
      cursor: pointer;
      min-width: 80px;
      text-align: center;
      border-bottom: 2px solid rgba(0,0,0,0);
    }
    .m-item-a {
      color: #dc0023;
      border-bottom: 2px solid #dc0023;
    }
  }
  .at-list {
    width: 100%;
    height: 40px;
    align-items: center;
    display: flex;
    overflow-x: auto;
    .at-item {
      margin-right: 30px;
      cursor: pointer;
    }
    .at-item-a {
      color: #dc0023;
    }
    .at-item:hover {
      color: #dc0023;
    }
  }
}
</style>
