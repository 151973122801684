<template>
  <div class="content">
    <div class="left">
      <div class="group-list" v-if="groupList && groupList.length > 0">
        <div class="group-item" @click="chooseGroup = item" :class="{'a-group-item': item.id === chooseGroup.id}" v-for="item in groupList" :key="item.id">
          <div class="item">{{item.name}}</div>
          <div class="item">{{item.startTime.slice(0, 10) + ' - ' + item.endTime.slice(0, 10)}}</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="worker-list" v-if="showWorkerList && showWorkerList.length > 0">
        <div class="worker-item" v-for="item in showWorkerList" :key="item.id">
          <div class="item">{{item.workerName}}</div>
          <div class="item">{{item.roleName}}</div>
        </div>
      </div>
      <div v-else class="no-date">
        暂无数据
      </div>
    </div>
    <div class="no-date" v-if="!groupList || groupList.length == 0">
      暂无数据
    </div>
  </div>
</template>

<script>
import {
  localstorageHelper
} from '@/fmlib'

import {
  fileRequest
} from '@/api'

export default {
  props: {
    orgId: {
      type: Number
    }
  },
  data () {
    return {
      groupList: [],
      workerList: [],
      chooseGroup: {}
    }
  },
  computed: {
    showWorkerList () {
      return this.workerList.filter(v => v.groupId === this.chooseGroup.id)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      let allGroupList = localstorageHelper.getData('orgGroups') || []
      this.groupList = allGroupList.filter(v => v.orgId === this.orgId)
      this.chooseGroup = this.groupList.length > 0 ? this.groupList[0] : null
      let allWorkerList = localstorageHelper.getData('orgGroupWorkers') || []
      this.workerList = allWorkerList.filter(v => v.orgId === this.orgId)
    },
    async loadFile () {
      this.imgSrc = null
      this.loadingFile = true
      try {
        let fileData = await fileRequest.download({
          id: this.orgData.otherInfo.logoFileId
        })
        if (fileData) {
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.imgSrc = src
        } else {
          this.imgSrc = null
        }
      } catch (error) {
        this.imgSrc = null
      }
      this.loadingFile = false
    }
  }
}
</script>

<style scoped lang="less">
.left {
  width: 250px;
  height: 100%;
  overflow-y: auto;
  margin-right: 20px;
  padding-right: 20px;
}
.right {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}
.content {
    display: flex;
  }
.org-item {
  cursor: pointer;
  background: #fff;
  box-shadow:2px 2px 6px rgba(0,0,0,0.1);
  overflow-y: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
  }
  .c {
    width: 100%;
    color: #666;
    text-align: center;
    padding: 5px 10px;
  }
  .label {
    padding-left: 10px;
    min-width: 80px;
    color: #666;
  }
  .info-item {
    display: flex;
  }
  .a-img {
    height: 166px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #ddd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
.no-date {
    text-align: center;
    font-size: 20px;
    color: #999;
    line-height: 100px;
  }
  .group-item {
    border: 1px solid #e8eaec;
  cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    .item {
      text-align: center;
    }
  }
  .a-group-item {
    color: #dc0023;
    border: 1px solid #dc0023;
    box-shadow:2px 2px 6px rgba(0,0,0,0.1);
  }
.worker-item {
  border: 1px solid #e8eaec;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 20px;
    .item {
      text-align: center;
    }
}
.worker-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
</style>
